import * as React from "react";

const IdleIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={10} y={10} width={4} height={4} rx={2} fill="currentColor" />
  </svg>
);

export default IdleIcon;
