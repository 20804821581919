import styled from "@emotion/styled";
import React from "react";
import PlusIcon from "@/components/icons/PlusIcon";
import ReadIcon from "@/components/icons/ReadIcon";
import IdleHoverIcon from "@/components/icons/IdleHoverIcon";
import IdleIcon from "@/components/icons/IdleIcon";
import { Box } from "@oskarengstrom/sage-ui";
import BackIcon from "../icons/BackIcon";
import PlayIcon from "../icons/PlayIcon";
import CopyIcon from "../icons/CopyIcon";

export default function IconButton({
  color,
  iconColor,
  icon = "plus",
  iconSize = 24,
  size = "2.5rem",
  className,
  ...restProps
}) {
  const Icon = ({ ...restProps }) => {
    switch (icon) {
      case "plus":
        return <PlusIcon {...restProps} />;
      case "read":
        return <ReadIcon {...restProps} />;
      case "back":
        return <BackIcon {...restProps} />;
      case "play":
        return <PlayIcon {...restProps} />;
      case "link":
        return <CopyIcon {...restProps} />;
      case "canvas":
        return <PlusIcon {...restProps} />;
      case "category":
        return <ReadIcon {...restProps} />;
      case "default":
        return <IdleHoverIcon {...restProps} />;
      case "idle":
        return <IdleIcon {...restProps} />;
    }
  };

  return (
    <Wrapper
      color={color}
      iconColor={iconColor}
      height={size}
      width={size}
      className={className}
      {...restProps}
    >
      <Icon size={iconSize} />
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  border-radius: 100%;

  /* background-color: ${(props) => props.color}; */
  background-color: ${(props) => props.color || props.theme.palette.background};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme, iconColor }) =>
    iconColor ? iconColor : theme.constants.colors.black};

  transition: all 80ms ease-out;

  /* box-shadow: 0px 0px 0px 2px inset black; */
`;
