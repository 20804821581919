import * as React from "react";

const PlayIcon = ({ size = 24, ...restProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="m21.384 11.363-13.49-8.25a.75.75 0 0 0-1.144.638v16.5a.75.75 0 0 0 1.144.637l13.49-8.25a.74.74 0 0 0 0-1.275Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

export default PlayIcon;
