import * as React from "react";

const PlusIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={11} y={4} width={2} height={16} rx={1} fill="currentColor" />
    <rect
      x={20}
      y={11}
      width={2}
      height={16}
      rx={1}
      transform="rotate(90 20 11)"
      fill="currentColor"
    />
  </svg>
);

export default PlusIcon;
