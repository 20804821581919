import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import gsap from "gsap/dist/gsap";
import Flip from "gsap/dist/Flip";
import styled from "@emotion/styled";
import { ThemeContext, useTheme } from "@emotion/react";
import { findValueInObject } from "@oskarengstrom/sage-ui";
gsap.registerPlugin(Flip);

const elements = ["#pinktop"];

export function useTransition() {
  const router = useRouter();
  const tl = useRef();
  const theme = useTheme(ThemeContext);

  const navigate = (href, color) => {
    // if on this page, do nothing
    if (href === "/" && router.pathname === href) return;

    router.prefetch(href);
    tl.current = gsap.timeline();

    tl.current.set(
      elements,
      {
        visibility: "visible",
        backgroundColor:
          (color && findValueInObject(color, theme)) || color || "white",
      },
      0
    );

    tl.current.fromTo(
      elements,
      {
        // height: 0,
        opacity: 0,
      },
      {
        // height: "50%",
        opacity: 1,
        duration: 0.3,
        ease: "linear",
        onComplete: () => {
          router.push(href);
        },
      },
      0.1
    );
  };

  const TransitionLink = ({ children, href, color, ...props }) => {
    if (typeof window !== "undefined") router.prefetch(href);

    const handleClick = (e) => {
      navigate(href, color);
    };

    return (
      <a
        {...props}
        onClick={handleClick}
        // onTouchStart={handleClick}
      >
        {children}
      </a>
    );
  };

  return { TransitionLink, navigate };
}

export const TransitionWrapper = ({ children }) => {
  const router = useRouter();
  const tl = useRef();

  function onRouteChangeComplete() {
    tl.current = gsap.timeline();
    tl.current.to(
      elements,
      {
        // height: 0,
        opacity: 0,
        duration: 0.3,
        ease: "linear",
        onComplete: () => {
          gsap.set(elements, { visibility: "hidden" });
        },
      },
      0
    );
  }

  useEffect(() => {
    // router.events.on("routeChangeStart", onRouteChangeStart);
    router.events.on("routeChangeComplete", onRouteChangeComplete);
    router.events.on("routeChangeError", onRouteChangeComplete);
    return () => {
      // router.events.off("routeChangeStart", onRouteChangeStart);
      router.events.off("routeChangeComplete", onRouteChangeComplete);
      router.events.off("routeChangeError", onRouteChangeComplete);
    };
  }, []);

  return (
    <div id="transition-component">
      <Wrapper>
        <PinkTop id="pinktop" />
        {/* <PinkBottom id="pinkbottom" /> */}
      </Wrapper>
      {children}
    </div>
  );
};

const Wrapper = styled.div`
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
`;

const BgBase = styled.div`
  visibility: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PinkTop = styled(BgBase)`
  z-index: 2;
  top: 0;
  background: ${({ theme }) => theme.constants.colors.pink.secondary};
`;

// const PinkBottom = styled(BgBase)`
//   z-index: 2;
//   bottom: 0;
//   background: ${({ theme }) => theme.constants.colors.pink.secondary};
// `;
