import * as React from "react";

const CopyIcon = ({ size = 24, ...restProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="m11.465 6.694 1.857-1.856a4.134 4.134 0 0 1 5.84 5.84l-2.653 2.644a4.117 4.117 0 0 1-5.831 0"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="m12.534 17.306-1.856 1.857a4.134 4.134 0 0 1-5.84-5.841l2.653-2.644a4.116 4.116 0 0 1 5.83 0"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

export default CopyIcon;
