import { useEffect } from "react";

const CookieBotScript = () => {
  const cbid = "6bf7a407-73a7-4855-8079-58c89cd35b5e";
  useEffect(() => {
    const existingScript = document.querySelector(
      `script[data-cbid="${cbid}"]`
    );
    if (existingScript) return;

    const script = document.createElement("script");
    script.src = "https://consent.cookiebot.com/uc.js";
    script.setAttribute("data-cbid", cbid);
    script.setAttribute("data-blockingmode", "auto");
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    function applyCookieBannerStyles() {
      console.log("applyCookieBannerStyles");
      const cookieBanner = document.querySelector("#CybotCookiebotDialog");
      if (!cookieBanner) return;

      const buttonToMove = cookieBanner.querySelector(
        "#CybotCookiebotDialogBodyEdgeMoreDetailsLink"
      );

      const targetDiv = cookieBanner.querySelector(
        "#CybotCookiebotDialogBodyButtonsWrapper"
      );

      targetDiv.insertBefore(buttonToMove, targetDiv.firstChild);

      cookieBanner.style = "opacity:1;";

      clearInterval(interval);
    }
    const interval = setInterval(applyCookieBannerStyles, 500);

    return () => clearInterval(interval);
  }, []);

  return null;
};

export default CookieBotScript;
