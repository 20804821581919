import { NavigationContextProvider } from "@/contexts/NavigationContext";
import { CustomGridContextProvider } from "@/contexts/CustomGridContext";
import { PrismicProvider } from "@prismicio/react";
import { PrismicPreview } from "@prismicio/next";
import { repositoryName, linkResolver } from "prismic-configuration";
import Link from "next/link";
import { Global, css } from "@emotion/react";
import "@/styles/globals.css";
import "@/styles/reset.css";
import { SageProvider, Typography } from "@oskarengstrom/sage-ui";
import { baseTheme } from "@/styles/theme";
import localFont from "@next/font/local";
import { TransitionWrapper } from "@/hooks/Transition";
import { RecoilRoot } from "recoil";
import Pointer from "@/contexts/pointer/Pointer";
import { gsap } from "gsap";
import CookieBotScript from "@/components/CookieBotScript";

gsap.config({
  nullTargetWarn: false,
});

const icaRubrik = localFont({
  name: "IcaRubrik",
  display: "swap",
  src: [
    {
      path: "../fonts/ICARubrik-Medium.ttf",
      weight: "500",
      format: "truetype",
      style: "normal",
    },
    {
      path: "../fonts/ICARubrik-Bold.otf",
      weight: "700",
      format: "opentype",
      style: "normal",
    },
  ],
});

const icaText = localFont({
  name: "IcaText",
  display: "swap",
  src: [
    {
      path: "../fonts/ICATextNy-Regular.ttf",
      weight: "400",
      format: "truetype",
      style: "normal",
    },
    {
      path: "../fonts/ICATextNy-Bold.otf",
      weight: "700",
      format: "opentype",
      style: "normal",
    },
    {
      path: "../fonts/ICATextNy-Black.otf",
      weight: "800",
      format: "opentype",
      style: "normal",
    },
  ],
});

const App = ({ Component, pageProps }) => {
  return (
    <SageProvider theme={baseTheme}>
      <PrismicProvider
        internalLinkComponent={(props) => <Link {...props} />}
        linkResolver={linkResolver}
      >
        <PrismicPreview repositoryName={repositoryName}>
          <RecoilRoot>
            <NavigationContextProvider>
              <CustomGridContextProvider>
                <Global
                  styles={css`
                    :root {
                      --ica-rubrik: ${icaRubrik.style.fontFamily};
                      --ica-text: ${icaText.style.fontFamily};
                    }
                  `}
                />

                <TransitionWrapper>
                  <Pointer />
                  <Component {...pageProps} />
                  <CookieBotScript />
                </TransitionWrapper>
              </CustomGridContextProvider>
            </NavigationContextProvider>
          </RecoilRoot>
        </PrismicPreview>
      </PrismicProvider>
    </SageProvider>
  );
};

export default App;
