import { useState, useEffect } from "react";

const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(undefined);

  function isTouchDevice_() {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    const isTouchDevice =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;
    const isIPadInLandscape = window.matchMedia(
      "(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)"
    ).matches;
    const isTouchScreenDevice = window.matchMedia("(pointer: coarse)").matches;

    return (
      isMobile || isTouchDevice || isIPadInLandscape || isTouchScreenDevice
    );
  }

  useEffect(() => {
    function handleResize() {
      if (typeof window === "undefined") return;
      setIsTouchDevice(isTouchDevice_());
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isTouchDevice_]);

  return isTouchDevice;
};

export default useIsTouchDevice;
