import * as prismic from "@prismicio/client";
import * as prismicH from "@prismicio/helpers";
import * as prismicNext from "@prismicio/next";
import sm from "./sm.json";

export const repositoryName = prismic.getRepositoryName(sm.apiEndpoint);

export const linkResolver = (doc) => {
  if (
    doc.link_type !== "Web" &&
    doc.link_type !== "Media" &&
    doc.link_type !== "Any"
  ) {
    switch (doc.type) {
      case "page": {
        return `/${doc.uid}`;
      }
      case "homepage": {
        return `/`;
      }
      case "category_page": {
        return `/kategori/${doc.uid}`;
      }
      case "article": {
        return `/artikel/${doc.uid}`;
      }

      default: {
        return "/";
      }
    }
  } else if (doc.link_type === "Web") {
    return doc.url;
  } else if (doc.link_type === "Media") {
    return doc.url;
  } else if (doc.link_type === "Any") {
    return null;
  }

  return "";
};

export const createClient = (config = {}) => {
  const client = prismic.createClient(sm.apiEndpoint, {
    ...config,
  });

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
};

export function cleanCMSData(data) {
  if (data) {
    const stringified = JSON.stringify(data);
    const cleaned = stringified
      .replaceAll("\u2028", "")
      .replaceAll("\u2029", "");
    return JSON.parse(cleaned);
  }
  return data;
}
