import React, { useReducer, useEffect } from "react";
import { useRouter } from "next/router";

export const NavigationContext = React.createContext({});

const stateMachine = (state, action) => {
  switch (state.status) {
    case "loading": {
      switch (action.type) {
        case "LOADED": {
          return {
            ...state,
            status: "ready",
          };
        }
      }
    }
    case "ready": {
      switch (action.type) {
        case "TRANSITION": {
          return {
            ...state,
            status: "transitioning",
            ...action.payload,
          };
        }
        case "NAVIGATE": {
          return {
            ...state,
            status: "loading",
            transitionType: action.payload,
            activeSectionId: null,
          };
        }
      }
    }
    case "transitioning": {
      switch (action.type) {
        case "NAVIGATE": {
          return {
            ...state,
            status: "loading",
            activeSectionId: null,
            transitionType: action.payload,
          };
        }
      }
    }
    default:
      return state;
  }
};

const initialState = {
  status: "ready",
  transitionType: "forward",
  activeSectionId: null,
  color: null,
};

export const NavigationContextProvider = ({ children }) => {
  const router = useRouter();
  const [state, dispatch] = useReducer(stateMachine, initialState);

  useEffect(() => {
    function handleRouteChangeComplete(url) {
      dispatch({ type: "LOADED" });
    }

    function handleRouteChangeStart(url) {
      dispatch({
        type: "NAVIGATE",
        payload: url === "/" && router.asPath !== "/" ? "back" : "forward",
      });
    }

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router.events, router.asPath]);

  return (
    <NavigationContext.Provider
      value={{
        ...state,
        onLoaded: () => dispatch({ type: "LOADED" }),
        onTransition: (sectionId, color) =>
          dispatch({
            type: "TRANSITION",
            payload: {
              activeSectionId: sectionId,
              color,
            },
          }),
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
