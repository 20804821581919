import { atom } from "recoil";

export const pointerState = atom({
  key: "pointerState",
  default: false,
  //   default: {
  //     type: "default" || "canvas" || "category",
  //     color:
  //       "pink" ||
  //       "purple" ||
  //       "blue" ||
  //       "brown" ||
  //       "green" ||
  //       "yellow" ||
  //       "greyscale",
  //   },
});

export const idlePointerState = atom({
  key: "idlePointerState",
  default: {
    type: "idle" || "back",
  },
});

// const setPointer = useSetRecoilState(pointerState);

// onMouseEnter={() => setPointer({ type: "default" })}
// onMouseLeave={() => setPointer(false)}

// onMouseEnter={() => setPointer({ type: "canvas", color: theme.palette.primary.lighter })}
// onMouseLeave={() => setPointer(false)}

// onMouseEnter={() => setPointer({ type: "category", color: theme.palette.primary.lighter })}
// onMouseLeave={() => setPointer(false)}

// ------------------------------

// const setIdlePointerState = useSetRecoilState(idlePointerState);

// useEffect(() => {
//     setIdlePointerState({ type: "back" });
// }, []);
