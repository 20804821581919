import styled from "@emotion/styled";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { idlePointerState, pointerState } from "./pointerState";
import {
  Box,
  Typography,
  mq,
  useResponsive,
  useSSRLayoutEffect,
} from "@oskarengstrom/sage-ui";
import gsap from "gsap";
import { ThemeContext, useTheme } from "@emotion/react";
import IconButton from "@/components/ui/IconButton";
import useIsTouchDevice from "@/hooks/useIsTouchDevice";

export default function Pointer() {
  const [active, setActive] = useState(false);
  const { isMobile, breakpointIndex } = useResponsive();
  const pointer = useRecoilValue(pointerState);
  const idlePointer = useRecoilValue(idlePointerState);
  const theme = useTheme(ThemeContext);
  const { white } = theme.constants.colors;
  const { text } = theme.palette;
  const isTouchDevice = useIsTouchDevice();

  // useEffect(() => {
  //   if (isMobile || breakpointIndex < 1) {
  //     const pointer = document.getElementById("custom_pointer");
  //     pointer.style.display = "none";
  //   } else {
  //     pointer.style.display = "block";
  //   }
  // });

  useSSRLayoutEffect(
    () => {
      if (isTouchDevice) return;
      // if (isMobile || breakpointIndex < 1) {
      //   const pointer = document.getElementById("custom_pointer");
      //   pointer.style.display = "none";
      //   return;
      // }

      const element = document.body;
      // element.style.cursor = "none";
      const pos = { x: window.innerWidth / 4, y: window.innerHeight / 4 };
      const mouse = { x: pos.x, y: pos.y };
      // let velocity = 0;
      const speed = 0.8;

      const xSet = gsap.quickSetter("#custom_pointer", "x", "px");
      const ySet = gsap.quickSetter("#custom_pointer", "y", "px");

      const mouseMove = (e) => {
        mouse.x = e.x;
        mouse.y = e.y;
      };

      const tickerFunc = () => {
        // adjust speed for higher refresh monitors
        const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

        pos.x += (mouse.x - pos.x) * dt;
        pos.y += (mouse.y - pos.y) * dt;
        xSet(pos.x);
        ySet(pos.y);
      };

      const mouseEnter = () => {
        setActive(true);
      };
      const mouseLeave = () => {
        setActive(false);
      };

      // const onFocus = (e) => {
      //   // set the cursor to none on the html element
      //   const transparentImage = new Image();
      //   transparentImage.src = "data:image/png;base64,iVBORw0KG...";
      //   document.body.style.cursor = `url('${transparentImage.src}'), auto`;

      //   // console.log(element);
      //   element.style.caretColor = "transparent";
      //   element.style.cursor = "none";
      //   element.style.color = "transparent";
      //   element.style.textShadow = "0 0 0 transparent";
      // };

      window.addEventListener("mousemove", mouseMove);
      element.addEventListener("mouseenter", mouseEnter);
      element.addEventListener("mouseleave", mouseLeave);
      // window.addEventListener("focus", onFocus);
      gsap.ticker.add(tickerFunc);

      return () => {
        window.removeEventListener("mousemove", mouseMove);
        element.removeEventListener("mouseenter", mouseEnter);
        element.removeEventListener("mouseleave", mouseLeave);
        // window.removeEventListener("focus", onFocus);
        gsap.ticker.remove(tickerFunc);
      };
    },
    [isMobile, breakpointIndex],
    isTouchDevice
  );

  const PointerComponent = () => {
    if (pointer.type === "canvas") {
      return (
        <PointerWrapper color={white}>
          <IconButton color={pointer.color} icon={pointer.type} />

          <Typography
            fontSize="15px"
            lineHeight="18px"
            whiteSpace="nowrap"
            pr="1rem"
            color={text.primary}
          >
            Utforska
          </Typography>
        </PointerWrapper>
      );
    }

    if (pointer.type === "category") {
      return (
        <PointerWrapper color={pointer.color}>
          <IconButton color={white} icon={pointer.type} />

          <Typography
            fontSize="15px"
            lineHeight="18px"
            whiteSpace="nowrap"
            pr="1rem"
            color={white}
          >
            Läs artikel
          </Typography>
        </PointerWrapper>
      );
    }

    if (pointer.type === "default") {
      return (
        <PointerWrapper>
          <IconButton
            color={"none"}
            icon={defPointer ? "default" : "idle"}
            size={defPointer ? "2.15rem" : "2.5rem"}
            css={{
              boxShadow: "0px 0px 0px 2px inset black",
            }}
          />
        </PointerWrapper>
      );
    }
  };

  const defPointer = pointer.type === "default";

  return (
    <Wrapper id="custom_pointer" active={active}>
      <Inner>
        <PointerComponent />

        {!pointer &&
          (idlePointer.type === "idle" ? (
            <PointerWrapper>
              <IconButton
                color={"none"}
                icon={defPointer ? "default" : "idle"}
                size={defPointer ? "2.15rem" : "2.5rem"}
                css={{
                  boxShadow: "0px 0px 0px 2px inset black",
                }}
              />
            </PointerWrapper>
          ) : (
            <PointerWrapper>
              <IconButton
                color={"none"}
                icon={"back"}
                css={{
                  boxShadow: "0px 0px 0px 2px inset black",
                }}
              />
            </PointerWrapper>
          ))}
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  user-select: none;
  pointer-events: none;
  /* opacity: ${(props) => (props.active ? 1 : 0)}; */
  display: ${(props) => (props.active ? "block" : "none")};
  ${mq.only.touchDevice} {
    display: none;
  }
`;

const Inner = styled(Box)`
  position: absolute;
  transform: translate(-50%, -50%);
`;

const PointerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: ${(props) => props.color};
  padding: 2px;
  /* padding-right: 1rem; */
  border-radius: 22px;
`;
