import React, { useReducer } from "react";

export const CustomGridContext = React.createContext({
  grid: {},
  setGrid: () => {},
});

export const CustomGridContextProvider = ({ children }) => {
  const [grid, setGrid] = useReducer((state, action) => {
    switch (action.type) {
      case "updateGrid":
        return {
          ...state,
          ...action.payload,
        };
      case "clearGrid":
        return {};
      default:
        return state;
    }
  }, {});

  return (
    <CustomGridContext.Provider
      value={{
        grid,
        setGrid,
      }}
    >
      {children}
    </CustomGridContext.Provider>
  );
};
