import * as React from "react";

const IdleHoverIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={8} y={8} width={8} height={8} rx={4} fill="currentColor" />
  </svg>
);

export default IdleHoverIcon;
