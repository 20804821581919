import { ThemeContext, useTheme } from "@emotion/react";
import { SageProvider } from "@oskarengstrom/sage-ui";

export const constants = {
  colors: {
    black: "#000000",
    white: "#ffffff",
    alpha: {
      grey: "#949494",
      lightgrey: "#F6F6F6",
    },
    red: {
      primary: "#E30613",
    },
    pink: {
      primary: "#F087B6",
      secondary: "#F7C3DB",
      tertiary: "#FDEEF5",
    },
    purple: {
      primary: "#964D92",
      secondary: "#CBA6C8",
      tertiary: "#F2EAF2",
    },
    maroon: {
      primary: "#890A52",
    },
    blue: {
      primary: "#0067A7",
      secondary: "#A6CAE0",
      tertiary: "#D9E8F2",
    },
    brown: {
      primary: "#866957",
      secondary: "#C3B4AB",
      tertiary: "#E7E1DD",
    },
    green: {
      primary: "#339352",
      secondary: "#99C9A8",
      tertiary: "#D6E9DC",
    },
    yellow: {
      primary: "#EDA945",
      secondary: "#F6D4A2",
      tertiary: "#FBEEDA",
    },
  },
  shadows: {
    button: "0 4px 16px 0px rgba(0, 0, 0, 0.08)",
  },
  radii: {
    medium: "1.5rem",
  },
};

export const baseTheme = {
  bp: ["36rem", "62rem", "85rem", "100rem"], // 576px, 992px, 1366px, 1600px
  section: {
    maxWidth: "125rem", // 2000px
    px: ["20px", "48px", "60px", "80px"],
  },
  palette: {
    background: constants.colors.white,
    primary: {
      main: constants.colors.pink.primary,
      light: constants.colors.pink.secondary,
      lighter: constants.colors.pink.tertiary,
    },
    text: {
      primary: constants.colors.black,
      secondary: constants.colors.alpha.grey,
      inverted: constants.colors.white,
    },
  },
  grid: {
    parent: {
      default: {
        base: [6, 6, 24],
        columnGap: [1.25, 2],
      },
    },
    child: {
      fullWidth: {
        gridColumn: ["span 6", "span 6", "span 24"],
      },
      text: {
        gridColumn: [
          "1 / 7",
          "2 / 6",
          "7 / span 12",
          "8 / span 10",
          // "9 / span 8",
        ],
      },
      image: {
        gridColumn: [
          "1 / 7",
          "1 / 7",
          "5 / span 16",
          "6 / span 14",
          // "7 / span 12",
        ],
      },
    },
  },
  constants: constants,
  typography: {
    variants: {
      default: {
        fontFamily: "var(--ica-text)",
        fontWeight: "400",
        fontSize: ["0.9375rem", null, "1rem"],
        lineHeight: ["1.25rem", null, "1.25rem"],
      },
      heading1: {
        fontFamily: "var(--ica-rubrik)",
        fontWeight: "500",
        fontSize: ["2.875rem", null, "5rem"],
        lineHeight: ["2.75rem", null, "5.5rem"],
      },
      heading1_articleHero: {
        fontFamily: "var(--ica-rubrik)",
        fontWeight: "500",
        fontSize: ["2.875rem", null, "4rem", "5rem"],
        lineHeight: ["2.875rem", null, "4rem", "5rem"],
      },
      heading2: {
        fontFamily: "var(--ica-rubrik)",
        fontWeight: "500",
        fontSize: ["2.25rem", null, "4rem"],
        lineHeight: ["2.25rem", null, "4.25rem"],
      },
      heading3: {
        fontFamily: "var(--ica-rubrik)",
        fontWeight: "500",
        fontSize: ["1.75rem", null, "2.5rem"],
        lineHeight: ["2rem", null, "2.75rem"],
      },
      heading4: {
        fontFamily: "var(--ica-rubrik)",
        fontWeight: "500",
        fontSize: ["1.1875rem", null, "1.625rem"],
        lineHeight: ["1.25rem", null, "2rem"],
      },
      paragraphLarge: {
        fontFamily: "var(--ica-text)",
        fontWeight: "400",
        fontSize: ["1rem", null, "1.25rem"],
        lineHeight: ["1.5rem", null, "2rem"],
      },
      paragraphMedium: {
        fontFamily: "var(--ica-text)",
        fontWeight: "400",
        fontSize: ["0.9375rem", null, "1rem"],
        lineHeight: ["1.25rem", null, "1.25rem"],
      },
      expert: {
        fontFamily: "var(--ica-text)",
        fontWeight: "400",
        fontSize: ["0.9375rem", null, "19px"],
        lineHeight: ["1.25rem", null, "26px"],
      },
      framtid: {
        fontFamily: "var(--ica-text)",
        fontWeight: "400",
        fontSize: ["0.9375rem", null, "19px"],
        lineHeight: ["1.25rem", null, "26px"],
      },
      labelLarge: {
        fontFamily: "var(--ica-rubrik)",
        fontWeight: "500",
        fontSize: ["1rem", null, "1.25rem"],
        lineHeight: ["1rem", null, "1.5rem"],
      },
      labelSmall: {
        fontFamily: "var(--ica-rubrik)",
        fontWeight: "500",
        fontSize: ["0.9375rem", null, "1rem"],
        lineHeight: ["1rem", null, "1.25rem"],
      },
    },
  },
};

export const themes = {
  pink: {
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        ...baseTheme.palette.primary,
        main: constants.colors.pink.primary,
        light: constants.colors.pink.secondary,
        lighter: constants.colors.pink.tertiary,
      },
    },
  },
  purple: {
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        ...baseTheme.palette.primary,
        main: constants.colors.purple.primary,
        light: constants.colors.purple.secondary,
        lighter: constants.colors.purple.tertiary,
      },
    },
  },
  blue: {
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        ...baseTheme.palette.primary,
        main: constants.colors.blue.primary,
        light: constants.colors.blue.secondary,
        lighter: constants.colors.blue.tertiary,
      },
    },
  },
  brown: {
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        ...baseTheme.palette.primary,
        main: constants.colors.brown.primary,
        light: constants.colors.brown.secondary,
        lighter: constants.colors.brown.tertiary,
      },
    },
  },
  green: {
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        ...baseTheme.palette.primary,
        main: constants.colors.green.primary,
        light: constants.colors.green.secondary,
        lighter: constants.colors.green.tertiary,
      },
    },
  },
  yellow: {
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        ...baseTheme.palette.primary,
        main: constants.colors.yellow.primary,
        light: constants.colors.yellow.secondary,
        lighter: constants.colors.yellow.tertiary,
      },
    },
  },
  greyscale: {
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        ...baseTheme.palette.primary,
        main: constants.colors.alpha.grey,
        light: constants.colors.alpha.lightgrey,
        lighter: constants.colors.alpha.lightgrey,
      },
    },
  },
};

export const SageProviderCMS = ({ children, theme }) => {
  const currentTheme = useTheme(ThemeContext);
  if (!theme) return { children };
  return (
    <SageProvider theme={themes[theme?.slug] || currentTheme}>
      {children}
    </SageProvider>
  );
};
